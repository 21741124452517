import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FatwaService {
  constructor(private http: HttpClient) {}

  getListFatwas(data:any): Observable<any> {
    let httpParams = new HttpParams();
    Object.keys(data).forEach(function (key) {
        if (key && data[key] != undefined && data[key] != null && data[key] != '') {
            httpParams = httpParams.append(key, data[key]);
        }
    });
    return this.http.get<any>(environment.citizensApi + "/fatwas?" + httpParams)
  }

  getFatwa(id: string): Observable<any> {
    return this.http.get<any>(environment.citizensApi + '/fatwas/' + id);
  }

  getFatwaByCode(id: string): Observable<any> {
    return this.http.get<any>(environment.citizensApi + '/citizen-fatwas/' + id);
  }
  getSubjects(): Observable<any> {
    return this.http.get<any>(environment.citizensApi + '/subjects' );
  }
  setFatwaRequest(data:any): Observable<any> {
    return this.http.post<any>(environment.citizensApi + '/citizen-fatwas/ask',data );
  }
}
